import { Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import "./spaces-search.scss"
import { connect } from "react-redux";
import Helper from "../../../../Common/Helper";
import Spinner from "../../../../Components/Navigation/LoadingSpinner/Spinner";
import SearchCriteria, { IProps as ISearchCriteriaProps, ISearchCriteriaResult } from "./SearchCriteria";
import { RouteComponentProps, generatePath, withRouter } from "react-router-dom";
import momentBusiness from 'moment-business-days';
import MapButton from "../../../../Components/Buttons/MapButton/MapButton";
import ListButton from "../../../../Components/Buttons/ListButton/ListButton";
import FloorPlan, { IFloorPlanSpace as IFloorPlanSpace, IPagedFloorPlanSpaces } from "../../../../Components/Data/FloorPlan/FloorPlan";
import History from "history";
import { RouterProps } from "react-router-dom";
import { appContext } from "../../../../AppContext";
import SpaceCard, { Props as SpaceCardProps } from "../../../../Components/Cards/SpaceCard/SpaceCard";
import { IUserPreferences, IUserPreferencesNode } from "../../../../Providers.Api/UserPreferences/UserPreferenceRepository";
import Alert from "../../../../Components/Miscellaneous/Alert/Alert";
import IbssbreadcrumbLeft from '../../../../Components/Navigation/Breadcrumbs/IbssbreadcrumbLeft';
import { IPropsFromState } from "../../../../redux/Interfaces";
import { DateTime } from "luxon";
import { DateHelper } from "../../../../Common/DateHelper";
import { Box, Grid } from "@mui/material";
import { SpacesFilter } from "../../../../Providers.Api/Spaces/SpaceRepository";
import React from "react";
import Guid from "../../../../Common/Guid";
import { IbssPage } from "../../../../Components/Core/BasePage/IbssPage";
import { BuildingChangeReason } from "../../../../Components/Core/BasePage/Types";
import { PagedResponse } from "../../../../Providers.Api/Models";
import { AvailableSpace } from "./DataModels";

class SearchSpaces extends IbssPage<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get config() { return appContext().config; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; };
    private get apiCache() { return appContext().apiCache};

    private history: History.History;
    private listViewSpaces: AvailableSpace[] = [];
    private listViewCacheKey: string;
    private mapViewCacheKey: string;
    private get isOneLens() { return window.location.href.includes("/operational-services"); }

    constructor(props: IProps)
    {
        super(props);
        this.history = props.history;
        this.listViewCacheKey = "";
        this.mapViewCacheKey = "";

        this.state =
        {
            isLoading: false,
            spaceCards: [],
            openDrawer: false,
            searchData: ["building", "workType", "spaceType", "floor", "zone", "date", "startTime", "endTime"],
            searchCriteria: [],
            view: View.List,
            searchResults: SearchResults.NoResults,
            mapUrl: "",
            loadMap: Guid.empty,
            mapSpaces: [],

            // search criteria
            buildingId: -1,
            workspaceType: null,
            spaceType: null,
            spaceTypeLabel: null,
            floorId: null,
            zone: null,
            startTime: DateHelper.null(),
            endTime: DateHelper.null(),
            audioVisual: false,
            presentationAids: false,
            hearingAids: false,
            catering: false,
            linkedSpace: false,
            layouts: false,
            numberOfPeople: null,
            selected: '',
            skipToken: ''
        }
    }

    private _userPreferences: (IUserPreferences | null) = null;
    private get userPreferences(): IUserPreferences
    {
        if (this._userPreferences == null)
        {
            this._userPreferences = this.local.getUserPreferences();
        }
        return this._userPreferences;
    }

    public async componentDidMount(): Promise<void>
    {
        if (this.isOneLens)
        {
            this.onBuildingIdChanged<IMatchParams>(params => params.buildingid, (buildingId, reason) => this.buildingIdChanged(buildingId, reason));
        }

        const defaultView = View[this.userPreferences.SearchPrefs.DefaultSearchResults as keyof typeof View] ?? View.List;
        await this.setStateAsync({ view: defaultView });

        const defaultBuildingId = this.userPreferences.SearchPrefs.DefaultBuilding;
        const buildingId = (this.isOneLens ? this.appState.buildingId : defaultBuildingId) ?? -1;
        const spaceTypesByNodeId = Helper.getSpaceTypesByNodeId(defaultBuildingId ?? -1);

        if (defaultBuildingId == null)
        {
            await this.setStateAsync({ openDrawer: true });
        }
        else if (spaceTypesByNodeId.error)
        {
            this.alert.show(this.labels.HubLabelSetyourpreferences, this.labels.HubLabelSetUserPrefenceError, () => this.redirectToUserPrefPage());
        }

        this.setBuilding(buildingId);
    }

    private buildingIdChanged(buildingId: number, reason: BuildingChangeReason): Promise<void>
    {
        if (reason == "BuildingSelectorChanged")
        {
            const path = generatePath(this.props.match.path, { buildingid: buildingId });
            this.props.history.push(path);
        }
        return this.setBuilding(buildingId);
    }

    private async setBuilding(buildingId: number): Promise<void>
    {
        this.pageTitle = (this.isOneLens ? `${this.labels.HubLabelFacilityManagementText} ${this.appState.buildingName}` : this.labels.HubMenuSearchaSpace);

        const defaultFloor = this.userPreferences.Nodes.find(item => item.NodeId === buildingId);
        const floorId = defaultFloor?.DefaultFloor ?? null;

        const defaultSpaceType = Helper.getSpaceTypesByNodeId(buildingId);
        const spaceType = defaultSpaceType.result[0]?.Name ?? null;
        const spaceTypeLabel = defaultSpaceType.result[0]?.Label ?? null;

        const rootNode = this.local.getNodeData();
        const buildingConfig = this.config.getBuildingConfig(rootNode, buildingId);
        const defaultTimes = this.config.getDefaultTimes(buildingConfig, this.userPreferences.WorkingHoursPrefs, false);

        await this.setStateAsync({ buildingId: buildingId, floorId: floorId, spaceType: spaceType, spaceTypeLabel: spaceTypeLabel, startTime: defaultTimes.start, endTime: defaultTimes.end });

        if (buildingId != -1)
        {
            await this.makeSearchCriteria();
            await this.updateSearchResults();
        }
    }

    private redirectToUserPrefPage(): void
    {
        const { history } = this.props;
        history.push(`/flex-user-pref-workplace`);
    }

    private async showMapView(): Promise<void>
    {
        if(this.state.view !== View.Map)
        {
            await this.setStateAsync({ view: View.Map });
            await this.updateSearchResults();
        }
    }

    private async showListView(): Promise<void>
    {
        if(this.state.view !== View.List)
        {
            await this.setStateAsync({ view: View.List });
            await this.updateSearchResults();
        }    
    }

    private async updateSearchResults(): Promise<void>
    {
        switch (this.state.view)
        {
            case View.List:
                await this.updateListViewResults();
                break;

            case View.Map:
                await this.updateMapViewResults();
                break;

            default:
                throw new Error("Not supported.");
        }
    }

    private async updateListViewResults(): Promise<void>
    {
        const filter = this.getSpacesFilter();
        const key = JSON.stringify({ buildingId: this.state.buildingId, filter: filter });

        if (this.listViewCacheKey != key)
        {
            this.listViewCacheKey = key;
            this.listViewSpaces = [];
            await this.setStateAsync({ skipToken: "" });
            await this.loadMoreSpaces();
        }
        await this.setStateAsync({ searchResults: (this.listViewSpaces.length === 0 ? SearchResults.NoResults : SearchResults.List) });
    }

    private async loadMoreClicked(): Promise<void>
    {
        return this.loadMoreSpaces();
    }

    private async loadMoreSpaces(): Promise<void>
    {
        try
        {           
            this.setState({ isLoading: true });
            const filter = this.getSpacesFilter();
            const nodeId = filter.floorId ? filter.floorId : this.state.buildingId;
            let spaceData = [];
            let bookingDates = [];

            if(filter.availableFrom && filter.availableTo)
            {
                bookingDates.push({
                    Start_Time: filter.availableFrom.toUtcByNode(this.state.buildingId).toISO(),
                    End_Time: filter.availableTo.toUtcByNode(this.state.buildingId).toISO(),
                })
            }

            let spaceSetup: number | undefined;
            switch (true) 
            {
                case filter.linkedSpace && filter.layouts:
                    spaceSetup = 5;
                    break;
                case !filter.linkedSpace && filter.layouts:
                    spaceSetup = 1;
                    break;
                case filter.linkedSpace && !filter.layouts:
                    spaceSetup = 5;
                    break;
                default:
                    spaceSetup = undefined;
            }

            let body =
            {
                Booking_Dates: bookingDates,
                Space_Capacity: filter.minCapacity,
                Space_Type: filter.spaceType,
                Space_Work_Type: filter.workspaceType,
                Meta_Serv_Reqs_AV: filter.audioVisual ? 1 : undefined,
                Meta_Serv_Reqs_Catering: filter.catering ? 1 : undefined,
                Meta_Serv_Reqs_Hearing: filter.hearingAids ? 1 : undefined,
                Meta_Serv_Reqs_Presentation: filter.presentationAids ? 1 : undefined,
                Floor_Id: filter.floorId,
                Meta_Loc_Zone: filter.zone,
                Space_Setup: spaceSetup,
            };

            const spaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<AvailableSpace[]>>({
                nodeId: nodeId,
                top:25,
                skipToken:this.state.skipToken,
                body:body,
            })
            this.listViewSpaces.push(...spaces.value);
            const spaceList = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            
            for(let i =0 ; i<= spaceList.length -1 ; i++)
            {
                for(let j = 0; j<= this.listViewSpaces.length - 1 ; j++)
                {
                    if(spaceList[i].Space_Id === this.listViewSpaces[j].Space_Id)
                    {
                        spaceData.push(spaceList[i])
                    }
                }
            }

            await this.setStateAsync({
                skipToken: spaces.skipToken,
                spaceCards: spaceData.map(i => SpaceCardProps.fromSpace(i)),
            });
        }
        finally
        {
            this.setState({ isLoading: false });
        }
    }

    private async updateMapViewResults(): Promise<void>
    {
        const filter = this.getSpacesFilter();
        if (filter.floorId == null)
        {
            this.setState({ searchResults: SearchResults.MapTooManyFloors });
            return;
        }

        const floor = this.local
            .getNodeData()
            .Regions
            .flatMap(region => region.Buildings)
            .flatMap(building => building.Floors)
            .find(floor => floor.Node_Id === filter.floorId);
        
        await this.floorPlanSpacesRequested("", filter.floorId);

        await this.setStateAsync({ searchResults: SearchResults.Map, loadMap: Guid.new(), mapUrl: floor ? floor.Floor_MapURI : "" });
    }

    private async floorPlanSpacesRequested(skipToken: string, floorId: number): Promise<IPagedFloorPlanSpaces>
    {
        const filter = this.getSpacesFilter();
        const key = JSON.stringify({ buildingId: this.state.buildingId, filter: filter });
        let pagedSpacesForMap: IPagedFloorPlanSpaces;

        if (this.mapViewCacheKey == key && skipToken == "")
        {
            pagedSpacesForMap = { skipToken: "", spaces: this.state.mapSpaces };
        }
        else
        {

            let bookingDates = [];

            if(filter.availableFrom && filter.availableTo)
            {
                bookingDates.push({
                    Start_Time: filter.availableFrom.toUtcByNode(this.state.buildingId).toISO(),
                    End_Time: filter.availableTo.toUtcByNode(this.state.buildingId).toISO(),
                })
            }

            let body =
            {
                Booking_Dates: bookingDates,
                Space_Capacity: filter.minCapacity,
                Space_Type: filter.spaceType,
                Space_Work_Type: filter.workspaceType,
                Meta_Serv_Reqs_AV: filter.audioVisual ? 1 : undefined,
                Meta_Serv_Reqs_Catering: filter.catering ? 1 : undefined,
                Meta_Serv_Reqs_Hearing: filter.hearingAids ? 1 : undefined,
                Meta_Serv_Reqs_Presentation: filter.presentationAids ? 1 : undefined,
                Floor_Id: filter.floorId,
                Meta_Loc_Zone: filter.zone,
                Space_Setup: filter.linkedSpace && filter.layouts ? 5 : undefined
            };

            try
            {
                
                this.mapViewCacheKey = key;
                
                const pagedSpaces = await appContext().ibssApiClientV2.v2.byNodeid.spaces.search.post<PagedResponse<AvailableSpace[]>>({
                    nodeId: floorId,
                    top: 25,
                    skipToken: skipToken,
                    body:body
                })

                pagedSpacesForMap = {
                    skipToken: pagedSpaces.skipToken,
                    spaces: pagedSpaces.value.map(i => ({
                        id: i.Space_Id,
                        colour: "",
                        getColourFromData: true,
                        periodCurrentSpaceValue: 0,
                    })),
                };
                this.setState({ mapSpaces: pagedSpacesForMap.spaces });
            }
            finally
            {
            }
        }

        this.setState({ searchResults: (!skipToken && !pagedSpacesForMap.spaces) ? SearchResults.NoResults : SearchResults.Map });
        return pagedSpacesForMap;
    }

    private getSpacesFilter(): SpacesFilter
    {
        const state = this.state;
        return new SpacesFilter({
            isEnabled: true,
            workspaceType: state.workspaceType ?? undefined,
            spaceType: state.spaceType ?? undefined,
            floorId: state.floorId ?? undefined,
            zone: state.zone ?? undefined,
            availableFrom: state.startTime.setZoneByNode(this.state.buildingId, true),
            availableTo: state.endTime.setZoneByNode(this.state.buildingId, true),
            audioVisual: state.audioVisual,
            presentationAids: state.presentationAids,
            hearingAids: state.hearingAids,
            catering: state.catering,
            linkedSpace: state.linkedSpace,
            layouts: state.layouts,
            minCapacity: state.numberOfPeople ?? 1,
            metaBookableIs1or3or4or5: true,
        });
    }

    private async makeSearchCriteria(): Promise<void>
    {
        const userPreferences = this.local.getUserPreferences();
        let searchCriteria: ISearchCriteriaValue[] = [];
        const getStrtDate = Helper.getWkngDaysBySelectedBuilding(userPreferences.SearchPrefs.DefaultBuilding);
        const Occ_Wkng_Days_Stt = getStrtDate?.Occ_Wkng_Days_Stt ?? "1";
        const Occ_Wkng_Days_Stp = getStrtDate?.Occ_Wkng_Days_Stp ?? "5";
        const workingDayArray = Helper.getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))
        momentBusiness.updateLocale('us', { workingWeekdays: workingDayArray });
        const todaysDate = DateHelper.today().toFormatOrDefault();
        const todaysDashDate = DateHelper.today().toFormatOrDefault();
        const nextDay = momentBusiness(todaysDate).nextBusinessDay().format('yyyy-MM-DD');
        const date = `${todaysDashDate}T${userPreferences.WorkingHoursPrefs.UserEndTime ? userPreferences.WorkingHoursPrefs.UserEndTime : "18:30"}`
        const userPrefEndTime = DateHelper.fromIsoToJsDate(date);
        let startDateAndTime = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
        let endDateAndTime = DateHelper.fromIsoToJsDate(DateHelper.now().toString());

        if (startDateAndTime > userPrefEndTime)
        {
            startDateAndTime = DateHelper.fromIsoToJsDate(`${nextDay}T${userPreferences.WorkingHoursPrefs.UserStartTime}`);
            endDateAndTime = DateHelper.fromIsoToJsDate(`${nextDay}T${userPreferences.WorkingHoursPrefs.UserEndTime}`);
        }
        else
        {
            endDateAndTime = userPrefEndTime;
        }

        this.session.setFlexSpaceSearchCriteria(this.state.startTime, this.state.endTime);

        this.state.searchData.map((option: string) =>
        {
            if (option === 'building' && this.state.buildingId != -1)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Building.svg`), value: Helper.getBuildingNameUsingBuildingId(this.state.buildingId) ?? 'NO DATA' })
            }
            else if (option === 'workType' && this.state.workspaceType != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`), value: this.state.workspaceType ?? 'NO DATA' })
            }
            else if (option === 'spaceType' && this.state.spaceTypeLabel != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Desk.svg`), value: this.state.spaceTypeLabel ?? 'NO DATA' })
            }
            else if (option === 'floor' && this.state.floorId != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Floor.svg`), value: Helper.getFloorNameUsingFloorAndBuildingId(this.state.buildingId, this.state.floorId) ?? 'NO DATA' })
            }
            else if (option === 'zone' && this.state.zone != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Zone.svg`), value: this.state.zone ?? 'NO DATA' })
            }
            else if (option === 'date' && this.state.startTime != null)
            {
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Calendar.svg`), value: this.state.startTime?.toLocaleDateString() ?? 'NO DATA' })
            }
            else if (option === 'startTime' && this.state.startTime.isValid)
            {
                const startTime = this.state.startTime.toFormat("HH:mm");
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`), value: startTime ?? 'NO DATA' })
            }
            else if (option === 'endTime' && this.state.endTime.isValid)
            {
                const endTime = this.state.endTime.toFormat("HH:mm");
                searchCriteria.push({ src: (`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`), value: endTime ?? 'NO DATA' })
            }
        });
        await this.setStateAsync({ searchCriteria: searchCriteria });
    }

    private hideSearchCritera(): void
    {
        this.setState({ openDrawer: false });
    }

    private showSearchCriteria(): void
    {
        this.setState({ openDrawer: true });
    }

    private navigateToSpaceDetails(spaceId: string): void
    {
        this.history.push(`/flex-find-a-space/${this.state.buildingId}/searchaspace/${spaceId}`);
    }

    private mapFailedToLoad(): void
    {
        this.setState(
            {
                isLoading: false,
                searchResults: SearchResults.MapFailedToLoad,
            });
    }

    private async searchCriteriaChanged(result: ISearchCriteriaResult): Promise<void>
    {
        await this.setStateAsync({
            buildingId: result.buildingId,
            workspaceType: result.workspaceType,
            spaceType: result.spaceType,
            spaceTypeLabel: result.spaceTypeLabel,
            floorId: result.floorId,
            zone: result.zone,
            startTime: result.startTime,
            endTime: result.endTime,
            audioVisual: result.audioVisual,
            presentationAids: result.presentationAids,
            hearingAids: result.hearingAids,
            catering: result.catering,
            linkedSpace: result.linkedSpace,
            layouts: result.layouts,
            numberOfPeople: result.numberOfPeople,
        });
        await this.makeSearchCriteria();
        await this.updateSearchResults();
    }

    private keyPressed(event: React.KeyboardEvent<HTMLDivElement>): void 
    {

        if (event.key === 'Enter') 
        {
            event.preventDefault();
            return;
        }
        else if (!this.state.spaceCards) 
        {
            return;
        }

        let selectedIndex = this.state.spaceCards.findIndex(space => space.spaceId === this.state.selected);
        let nextSpaceId;

        switch (event.key)
        {
            case 'ArrowLeft': {
                nextSpaceId = this.state.spaceCards[selectedIndex - 1]?.spaceId;
                break;
            }
            case 'ArrowRight': {
                nextSpaceId = this.state.spaceCards[selectedIndex + 1]?.spaceId;
                break;
            }
            case 'ArrowUp': {
                const { x: currentXUp, y: currentYUp } = this.getLocation(this.state.selected);
                for (let i = selectedIndex - 1; i >= 0; i--)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.state.spaceCards[i].spaceId);
                    if (currentXUp === positionX && currentYUp >= positionY) 
                    {
                        nextSpaceId = this.state.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case 'ArrowDown': {
                const { x: currentXDown, y: currentYDown } = this.getLocation(this.state.selected);
                for (let i = selectedIndex + 1; i < this.state.spaceCards.length; i++)
                {
                    const { x: positionX, y: positionY } = this.getLocation(this.state.spaceCards[i].spaceId);
                    if (currentXDown === positionX && currentYDown <= positionY) 
                    {
                        nextSpaceId = this.state.spaceCards[i].spaceId;
                        break;
                    }
                }
                break;
            }
            case ' ': {
                this.navigateToSpaceDetails(this.state.selected);
                return;
            }
            default: {
                return;
            }
        }

        if (nextSpaceId) 
        {
            this.setState({ selected: nextSpaceId });
        }
    }

    private spaceCardFocussed(id: string): void 
    {
        this.setState({ selected: id });
    }

    private getLocation(id: string): ILocation 
    {
        const el = document.getElementById(id);
        if (!el) 
        {
            return { el: null, x: 0, y: 0 };
        }
        const location = el.getBoundingClientRect();
        const x = location.left + window.scrollX;
        const y = location.top + window.scrollY;
        return { el, x, y };
    };

    public render(): JSX.Element
    {
        const filterCriteraDrawerData: ISearchCriteriaProps =
        {
            open: this.state.openDrawer,
            closeClicked: () => this.hideSearchCritera(),
            updateSearchResults: result => this.searchCriteriaChanged(result),
            notificationList: this.props.notificationList,
            notificationReadedList: this.props.notificationReadedList,
            lightModeTheme: this.props.lightModeTheme,

            // search criteria
            buildingOptions: this.state.buildingId,
            selectedWorkspaceTypes: this.state.workspaceType ?? "Any",
            selectedSpaceTypes: this.state.spaceType ?? "Any",
            selectedFloor: (this.state.floorId == null ? "Any" : this.state.floorId.toString()),
            selectedZone: this.state.zone ?? "Any",
            startTime: this.state.startTime.toJSDate(),
            End_Date_For_filter_modal: this.state.endTime.toJSDate(),
            av: this.state.audioVisual,
            presentationAids: this.state.presentationAids,
            hearingAids: this.state.hearingAids,
            catering: this.state.catering,
            linkedSpace: this.state.linkedSpace,
            layouts: this.state.layouts,
            numberOfPeople: this.state.numberOfPeople?.toString() ?? "",
            onelensSpaceAnalyticsOverview: {
                buildingOption: "",
                floor: "",
                classType: "",
                WorkSpace: "",
                periodTypeValue: "",
                periodType: 0,
                buildingNodeId: 0,
                fmsfc_start_date_for_filter_modal: "",
                End_Date_For_filter_modal: ""
            }
        }
        const listButtonActive = (this.state.view === View.List);
        const mapButtonActive = (this.state.view === View.Map);

        return (
            <>
                <link rel="stylesheet" href="/src/pages/Flex/Search/SearchComponent.css"></link>
                {this.state.isLoading && <Spinner />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content" style={{ paddingBottom: "10px" }} >
                        <div className="space-box-cont">
                            <div className="left-space-box-cont flex-row-bredcrumb">
                                <IbssbreadcrumbLeft value={this.state.searchCriteria} />
                                {/* {this.state.searchCriteria.map((item: SearchCriteriaValue, ind: number) =>
                                    {
                                        return (
                                            <div className="space-box icon-text-inline" key={item.value + ind} style={{ cursor: "context-menu" }}>
                                                <span className="space-icon"><img src={item.src} alt="icon" /></span>
                                                <span className="space-text">{item.value} </span>
                                            </div>
                                        )
                                    })} */}
                            </div>
                            <div className="right-space-box-cont">
                                {!this.state.openDrawer ? (
                                    <div>
                                        <button type="button" className="edit-search btn-primary btn-md" onClick={() => this.showSearchCriteria()}>{this.labels.HubButtonEditsearchcriteria}</button>
                                    </div>
                                ) : ""}
                            </div>

                            {this.state.openDrawer ? (
                                <SearchCriteria {...filterCriteraDrawerData} />
                            ) : ""}

                        </div>
                        <Grid container className="space-box-cont">
                            <Grid sm={8} mb={1} className="left-space-box-cont flex-row-bredcrumb">
                                <div className="search-results-title">{this.labels.HubLabelSearchResults}</div>
                            </Grid>
                            <Grid sm={4} spacing={2} mb={1} className="btn-right-aligned">
                                <MapButton onClick={() => this.showMapView()} active={mapButtonActive} style={{ marginRight: "10px" }} />
                                <ListButton onClick={() => this.showListView()} active={listButtonActive} />
                            </Grid>
                            <Grid sm={12} className={"search-results-height mt-0 " + this.resultsCssClass}>
                                {this.renderResults()}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }

    private renderResults(): JSX.Element
    {
        switch (this.state.searchResults)
        {
            case SearchResults.NoResults:
                return (!this.state.isLoading ? <Alert key="noResults" title={this.labels.HubLabelNoSpacesAvailable} text={this.labels.HubLabelFlexSearchCriteriaNoSpaces} /> : <></>);

            case SearchResults.MapTooManyFloors:
                return (<Alert key="mapTooManyFloors" title={this.labels.HubLabelmapView} text={this.labels.HubLabelSelectAFloor} />);

            case SearchResults.MapFailedToLoad:
                return (<Alert key="mapFailedToLoad" title={this.labels.HubmapFailedToLoad} text={this.labels.HubLabelUsingTheListView} />);

            case SearchResults.Map:
                return (<FloorPlan key="map" url={this.state.mapUrl} loadSpaces={this.state.loadMap} onRequestSpaces={(skipToken, floorId) => this.floorPlanSpacesRequested(skipToken, floorId)} spaceModalClicked={spaceId => this.navigateToSpaceDetails(spaceId)} mapFailedToLoad={() => this.mapFailedToLoad()} floorId={this.state.floorId ?? 0} startTime={this.state.startTime} endTime={this.state.endTime} />);

            case SearchResults.List:
                return (
                    <>
                        <div
                            key="list"
                            className="space-card-container"
                            onKeyDown={e => this.keyPressed(e)}
                        >
                            {
                                this.state.spaceCards.map(props => (
                                    <SpaceCard
                                        key={props.spaceId}
                                        pointer={true}
                                        {...props}
                                        onClick={spaceId => this.navigateToSpaceDetails(spaceId)}
                                        buildingId={this.state.buildingId}
                                        onCardFocused={id => this.spaceCardFocussed(id)}
                                        focus={props.spaceId === this.state.selected}
                                    />))
                            }
                        </div>
                        { this.state.skipToken != "" &&
                            <Box
                                className="text-center my-4">
                                <button
                                    type="button"
                                    className="edit-search btn-primary btn-md"
                                    onClick={() => this.loadMoreClicked()}
                                >
                                    {this.labels.HubButtonLoadMore}
                                </button>
                            </Box>
                        }
                    </>
                );

            default:
                return (<></>);
        }
    }

    private get resultsCssClass(): string
    {
        switch (this.state.searchResults)
        {
            case SearchResults.NoResults:
            case SearchResults.MapTooManyFloors:
            case SearchResults.MapFailedToLoad:
                return "search-results-cont--alert";

            case SearchResults.Map:
                return "search-results-cont--map";

            case SearchResults.List:
                return "search-results-cont--list";

            default:
                return "";
        }
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria
    };
};

export default withRouter(connect(mapStateToProps)(SearchSpaces) as any);

enum View
{
    Map,
    List,
}

enum SearchResults
{
    NoResults,
    MapTooManyFloors,
    MapFailedToLoad,
    Map,
    List,
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState
{
}

interface IState
{
    isLoading: boolean;
    spaceCards: Array<SpaceCardProps>;
    openDrawer: boolean;
    searchData: Array<string>;
    searchCriteria: Array<ISearchCriteriaValue>;
    view: View;
    searchResults: SearchResults;
    mapUrl: string;
    loadMap: Guid;
    mapSpaces: IFloorPlanSpace[];

    // search criteria
    buildingId: number;
    workspaceType: (string | null);
    spaceType: (string | null);
    spaceTypeLabel: (string | null);
    floorId: (number | null);
    zone: (string | null);
    startTime: DateTime;
    endTime: DateTime;
    audioVisual: boolean;
    presentationAids: boolean;
    hearingAids: boolean;
    catering: boolean;
    linkedSpace: boolean;
    layouts: boolean;
    numberOfPeople: (number | null);
    selected: string;
    skipToken: string;
}

interface ISearchCriteriaValue
{
    src: string;
    value: string;
}

interface IMatchParams
{
    buildingid: string;
}

interface ILocation 
{
    el: HTMLElement | null;
    x: number;
    y: number;
}

interface ISpaceConfig
{
    id: string;
    colour:string;
    getColourFromData: boolean,
    periodCurrentSpaceValue: number,
}